import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import Layout from "../components/layout"
import SEO from "../components/seo"

import video1 from "../videos/1.mp4"
import poster1 from "../videos/1.png"
import video2 from "../videos/2.mp4"
import poster2 from "../videos/2.png"
import video3 from "../videos/3.mp4"
import poster3 from "../videos/3.png"
import video4 from "../videos/4.mp4"
import poster4 from "../videos/4.png"

const options = {
  settings: {
    overlayColor: "rgba( 255, 255, 255, .9 )",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "rgba( 0, 0, 0, .4 )",
    iconColor: "#fff",
    showDownloadButton: false,
    showAutoplayButton: false,
    showThumbnailsButton: false,
    showFullscreenButton: false,
  },
  caption: {
    showCaption: false,
  },
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 4500,
  speed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const quotes = [
  {
    quote:
      "Music expresses that which cannot be said and on which is impossible to be silent.",
    author: "- Victor Hugo -",
  },
  {
    quote: "Where words leave off, music begins.",
    author: "- Heinrich Heine -",
  },
  {
    quote:
      "I worked hard. Anyone who works as hard as I did can achieve the same results.",
    author: "- Johann Sebastian Bach -",
  },
  {
    quote: "Anyone who loves music can never be quite unhappy.",
    author: "- Franz Schubert -",
  },
  {
    quote:
      "Bach is like an astronomer who with the help of ciphers, finds the most wonderful stars... Beethoven embraced the universe with the power of his spirit...I do not climb so high. A long time ago I decided that my universe will be the soul and heart of man.",
    author: "- Frederic Chopin -",
  },
  {
    quote:
      'Music is heart of life. "She speaks love;" without it, there is no possible good and with it everything is beautiful.',
    author: "- Franz Liszt -",
  },
  {
    quote:
      "Music is enough for lifetime- but a lifetime is not enough for music.",
    author: "- Sergei Rachmaninoff-",
  },
  {
    quote: "The essence of beauty is unity in variety.",
    author: "- Felix Mendelssohn -",
  },
  {
    quote: "The music is not in the notes, but in the silence between.",
    author: "- Wolfgang Amadeus Mozart-",
  },
  {
    quote:
      "I wish you music to help with the burdens of life, and help you release your happiness to others.",
    author: "- Ludwig van Beethoven -",
  },
]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                originalImg
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SimpleReactLightbox>
        <SEO title="Tuition" />

        <section className="pb-10">
          <h1 className="text-3xl lg:text-4xl text-center pb-6">Gallery</h1>

          <div className="flex justify-center pb-6">
            <video controls poster={poster4}>
              <source src={video4} type="video/mp4" />
            </video>
          </div>

          <SRLWrapper options={options}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-10">
              {data.allFile.edges.map(image => (
                <div
                  key={image.node.id}
                  className="border border-gray-light rounded p-1 bg-white w-full max-w-md mx-auto"
                >
                  <a
                    href={image.node.childImageSharp.fluid.originalImg}
                    data-attribute="SRL"
                  >
                    <Img
                      className="w-full h-full fit-contain"
                      fluid={image.node.childImageSharp.fluid}
                      alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                    />
                  </a>
                </div>
              ))}
            </div>
          </SRLWrapper>
          <div className="max-w-lg mx-auto">
            <div className="video-wrapper overflow-hidden relative mt-8 md:mt-10">
              <video controls poster={poster1}>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="mt-8 md:mt-10 max-w-xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
            <div className="mx-auto">
              <video controls poster={poster2}>
                <source src={video2} type="video/mp4" />
              </video>
            </div>
            <div className="mx-auto">
              <video controls poster={poster3}>
                <source src={video3} type="video/mp4" />
              </video>
            </div>
          </div>
        </section>

        <Slider {...settings} className="pt-10">
          {quotes.map((q, i) => (
            <p key={`quote-${i}`} className="text-center leading-loose">
              <q>{q.quote}</q>
              <br />
              {q.author}
            </p>
          ))}
        </Slider>
      </SimpleReactLightbox>
    </Layout>
  )
}

export default IndexPage
